.home-exploreBanner-wrap .ex-title {
  font-size: 55px;
  letter-spacing: -0.25px;
}

.lg-icon-wrap .MuiAvatar-root {
background: white;
}
.sp-moreBenefits-wrap .lg-icon-wrap .MuiAvatar-root {
  background: #F1F9FF;
}
.sp-moreBenefits-wrap .card-tranparent-mb{
  background: rgba(3, 39, 68, 0.60);
  backdrop-filter: blur(12px);
}
.lg-icon-thumbnail.multi-fund .lg-icon-wrap .MuiAvatar-root {
  background-color: #F1F9FF;
}

.reachInvestors-section-bg-image{
  background-image:url("../../Assets//Images/Petals.png");
  background-repeat:no-repeat;
  background-position: center;
}

.reachInvestors-section-bg-image .card-tranparent-mb{
  background: rgba(3, 39, 68, 0.60);
  backdrop-filter: blur(12px);
}

.epCompo {
  font-size: 55px;
  letter-spacing: -0.25px;
}

.epCompo .invest-in {
  color: var(--white-color);
}

.epCompo .best-nft {
  color: var(--yellow-100);
}

.home-exploreBanner-wrap .ex-subtitle {
  font-size: var(--font-18);
  color: var(--white-color);
  margin-top: 24px;
}

.home-exploreBanner-wrap .ex-banner-image,
.home-exploreBanner-wrap .ex-banner-video {
    width: 950px;
    height: auto;
    position: absolute;
    right: -185px; 
    z-index: -3;
    top: -72px;
}
.home-exploreBanner-wrap .ex-banner-video{
  z-index: -3;
  top: 0;
  width:860px;
  background-color: transparent;
}
/* End */

.ep-hiw-btns .ep-invest-in,
.ep-hiw-btns .ep-provide-loan {
    height: 55px;
    width: 192px;
    border-radius: 14px;
    background: var(--yellow-100);
    padding: 6px 16px;
}

.epCompo .invest-in {
    color: var(--white-color);
}

.epCompo .best-nft {
    color: var(--yellow-100);
}
.ep-hiw-btns .ep-invest-in,
.ep-hiw-btns .ep-provide-loan {
  height: 55px;
  width: 192px;
  border-radius: 14px;
  background: var(--yellow-100);
  padding: 6px 16px;
}

.ep-hiw-btns .ep-provide-loan {
  background-color: #396d8d;
}

.hiw-btn-icon {
  color: var(--white-color);
}

.hiw-btn-text {
  color: var(--white-color);
}

.ep-hiw-btns {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.revolutionising-card-container{
  background: rgba(3, 39, 68, 0.60);


}

/* ====================================
Responsive
================================ */
@media only screen and (max-width: 1199px) {
  .home-exploreBanner-wrap .ex-title {
    font-size: 40px;
  }
  .home-exploreBanner-wrap .ex-subtitle {
    font-size: 16px;
  }
  .home-exploreBanner-wrap .ex-banner-image {
    width: 730px;
  }
} 

@media only screen and (max-width: 599px) {
  .home-exploreBanner-wrap .ex-title {
    font-size: 34px;
  }
}
