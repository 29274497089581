.main-sidebar {
  width: 100%;
  background: #05304a !important;
  border-radius: 0px 16px 16px 0px !important;
}
.main-sidebar-dic {
  width: 100%;
  background: #07487F !important;
  border-radius: 0px 16px 16px 0px !important;
}

.main-sidebar-dic .ctm-scroll {
  height: calc(100vh - 236px) !important;
}
.main-sidebar .ctm-scroll {
  height: calc(100vh - 236px) !important;
}

.sidebar-head {
  padding: 15px 24px;
}

.sidebar-head .helpCenter-btn {
  color: var(--blueGray-900);
  letter-spacing: 0.1px;
  font-weight: 600;
  height: 56px;
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 30px;
}
.sidebar-head .helpCenter-btn .MuiButton-startIcon svg {
  color: var(--yellow-800);
  font-size: 24px;
}
.sidebar-head .helpCenter-btn:hover {
  background: var(--yellow-500);
  color: #0f172a;
}
.sidebar-head hr.divider {
  border-color: #94a3b8;
}

.sidebar-head .title {
  font-size: var(--font-base);
  color: var(--white-color);
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-top: 15px;
}

/* Sidebar menu */

.sidebar-menu .menu-item a {
  font-size: var(--font-base);
  color: #fff;
  border-radius: 40px;
  display: block;
  padding: 15px 24px;
  white-space: nowrap;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  margin: 5px 0px;
}

.sidebar-menu .menu-item a .icon svg {
  width: 21px;
  height: auto;
  color: #5f89a3;
  margin-right: 7px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
}

.sidebar-menu .menu-item a.active,
.sidebar-menu .menu-item a:hover {
  background: #012338;
}

.sidebar-menu .menu-item a.active .icon svg,
.sidebar-menu .menu-item a:hover .icon svg {
  color: #e9deb9;
}

/*================================= 
End
=================================*/
