.sendOtp {
  color: var(--blue-800);
  font-size: 13px !important;
  font-weight: 500 !important;
  cursor: pointer;

}

.otpSendText {
  font-size: 13px;
  color: var(--blue-900);
  font-weight: 400;
  text-decoration: none;

}

.OtpRecieveText {
  font-size: 14px;
  color: var(--blue-300);
  font-weight: 500;
}

.OtpRecieveText .resend {
  font-size: 14px;
  color: var(--blue-300);
  font-weight: 500;
  text-decoration: underline;
}

.modal-body {
  background: var(--blue-900);
  border-radius: 24px 0px 0px 24px;
  padding: 36px;
}

.modal-body .headContent {
  display: flex;
  justify-content: space-between;
}

.modal-body .close-btn {
  cursor: pointer;
}

.headText {
  font-size: 25px !important;
}

.headTextReview {
  font-size: 21px !important;
  line-height: 1.5 !important;
}

a.link-wallet {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.link-review {
  text-decoration: underline;
  cursor: pointer;
}

.link-review.overview {
  font-weight: 600;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-button {
  background: var(--blue-600) !important;
  width: 100%;
  height: 52px;
  border-radius: 16px !important;
  font-size: 14px !important;
  color: var(--white-color) !important;
  text-transform: capitalize !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.walletIcon {
  color: var(--blue-100);
}

.modal-body-review {
  background: var(--blue-900);
  border-radius: 24px 0px 0px 24px;
  padding: 36px;

}

.modal-body-review .headContent {
  display: flex;
  justify-content: space-between;
}

.modal-body-review .close-btn {
  cursor: pointer;
}

.review-btn {
  background: var(--blue-600) !important;
  width: 75%;
  height: 52px;
  border-radius: 16px !important;
  font-size: 14px !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.review-modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  padding: 64px 5px;
  text-align: center;
}

.modalContentDone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  max-width: 70%;
  margin-bottom: 38px;
}

.main-content.loan {
  max-width: 710px !important;
}

.doneIcon-box {
  display: flex;
  justify-content: center;
}

.loan-btn {
  display: flex;
  justify-content: center;
}

.wallet-button.loan {
  max-width: 628px;
}

.modalContentDone .main-content .DoneIcon {
  position: relative;
  background: #10B981;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.modalContentDone .main-content .DoneIcon .doneIconSvg {
  position: absolute;
  top: 31px;
  right: 31px;
  height: 37px;
  color: #D1FAE5;
  width: 37px;
}

.modalContent-btn-box {
  width: 83%;
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.dic-NS-sale-details {
  height: 50px;
  margin: 16px 0px !important;
}

.dic-NS-sale-details,
.NS-parcel-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e2e8f0;
  border-radius: 8px;
  padding: 0px 8px;
}

.NS-parcel-details {
  padding: 0px 2px;
}

.dic-NS-sale-details-noPM {
  display: flex;
  justify-content: center;
}

.dic-NS-sale-details-noPM .MuiFormControl-root input.MuiInputBase-input,
.dic-NS-sale-details-noPM .disabledTextField {
  text-align: center;
  font-size: 24px;
  background-color: #e2e8f0;
  border-radius: 12px;
}

.dic-NS-sale-details-noPM .disabledTextField {
  max-width: 183px;
}

.dic-NS-sale-details .MuiFormControl-root input.MuiInputBase-input,
.dic-NS-sale-details-noPM .MuiFormControl-root input.MuiInputBase-input {
  text-align: center;
  font-size: 24px;
}

.dic-NS-sale-details .MuiFormHelperText-root,
.dic-NS-sale-details-noPM .MuiFormHelperText-root {
  text-align: center;
  margin-top: 0px;
}

.dic-NS-sale-details .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline,
.dic-NS-sale-details-noPM .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

.dic-NS-sale-details .MuiInputBase-input-MuiOutlinedInput-input,
.dic-NS-sale-details-noPM .MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px;
}

.dic-NS-sale-details-icon {
  background: #398CD1;
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.dic-NS-sale-details-icon .MuiButtonBase-root-MuiIconButton-root {
  color: var(--white-color);
}

.parcel-button {
  padding: 2px !important;
}

.dic-NS-sale-details-icon .dic-NS-sale-details-icon-btn .MuiSvgIcon-root {
  color: var(--white-color);
}

.voteIconBox {
  background-color: #AEDAFF;
  border-radius: 30px;
  padding: 1px 6px;
}

.voteIconBoxgray {
  background-color: #94A3B8;
  border-radius: 30px;
  padding: 1px 6px;
}

.voteIcon {
  height: 24px;
  width: 24px;
  margin-top: 5px;
}

.stepper {
  min-width: 54%;
}

.stepper.not-loan {
  display: flex;
  justify-content: center;
}

.stepper .stepLabelActive {
  font-size: 12px;
  color: #AEDAFF;
  font-weight: 500;
}

.stepLabel {
  font-size: 12px !important;
  color: #94A3B8;
  font-weight: 500 !important;
}

.vote-btn-box {
  display: flex;
  justify-content: center;
  width: 710px;
  gap: 13px;
}

.wallet-button.appr-btn {
  background: #10b981 !important;
}

.wallet-button.rjct-btn {
  background: #ef4444 !important;
}

.votingContent {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.loan-btn-box {
  display: flex;
  gap: 13px;
}

.votingContent .lA-txt-field {
  display: flex;
  background-color: var(--white-color);
  border-radius: 8px;
  margin-top: 14px;
}

.votingContent .lA-txt-field .stPi-stIcon {
  color: aquamarine;
}

.votingContent .lA-txt-field .plusIcon {
  height: 44px;
  margin-top: 5px;
  margin-left: 4px;
}

.votingContent .lA-txt-field .minusIcon {
  height: 44px;
  margin-top: 5px;
  margin-right: 4px;
}

.votingContent .ir-txt-field {
  background-color: var(--white-color);
  border-radius: 8px;
  margin-top: 14px;
  max-width: 84px;
}

.votingContent .ir-txt-field input {
  font-size: 22px !important;
  height: 19px;
}

.votingContent .lD-txt-field {
  background-color: var(--white-color);
  border-radius: 8px;
  margin-top: 14px;
  max-width: 110px;
}

.votingContent .lD-txt-field input {
  font-size: 22px !important;
  height: 19px;
}

.loanDetail {
  display: flex;
  flex-direction: column;
  gap: 37px;
}

.loan-content {
  display: flex;
  gap: 11px;
}

.votingContent .rLDetails.MuiBox-root.css-0 {
  display: flex;
  flex-direction: column;
  gap: 39px;
}

.votingContent .vote-subText {
  display: flex;
  justify-content: center;
}

.votingContent .rLDetail-form {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 737px;
}

.votingContent .rLDetails .text-1 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  margin-top: 0px !important;
}

.votingContent .rLDetails .text-2 {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px !important;

}

.votingContent .rLDetails .rLDetail-form .detail-box {
  background-color: var(--blue-500);
  border-radius: 8px;
  display: flex;
  height: 58px;
  width: 185px;
}

.votingContent .rLDetails .rLDetail-form .detail-box .dIcon {
  margin-left: 6px;
  height: 38px;
  margin-top: 10px;
  margin-bottom: 6px;
  border-radius: 8px;
  background-color: var(--blue-900);
}

.votingContent .rLDetails .rLDetail-form .detail-box .lD-txt {
  margin: 10px;
}

.votingContent .rLDetails .rLDetail-form .detail-box .lD-txt .lDetail-txt1 {
  font-size: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
}

.votingContent .rLDetails .rLDetail-form .detail-box .lD-txt .lDetail-txt2 {
  color: var(--palette-white-900, #fff);

  font-size: 16px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: -2px;
  margin-left: 12px;
}

.loanDone-btn-box {
  display: flex;
  gap: 13px;
}

.btn-icon {
  display: flex;
  gap: 17px;
  align-items: center;
  justify-content: center;
}

.voted-img {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6px;
}

.voted-img-box .avatar-dic {
  background-color: #ECFDF5;
  border: 1px #ECFDF5 !important;
  height: 60px;
  width: 60px;
}

.voted-img-box .avatar-dic-voted {
  height: 60px;
  width: 60px;
  background-color: #10b981 !important;
  border: 1px #0000 !important;
}

.voted-img-box .vote {
  color: #FFFF;
}

.voted-img-box .noVote {
  color: #398cd1;
}



.voted-img .voted-img-box>div {
  width: 64px;
  height: 64px;
  border: 2px solid #000;
  border-radius: 38px;

}

.voted-img .voted-img-box .box1 {
  background-color: #10b981;
  left: 0;
}

.voted-img .voted-img-box .box2 {
  background-color: #10b981;
  position: absolute;
  left: 44px;
}

.voted-img .voted-img-box .box3 {
  background-color: #10b981;
  position: absolute;
  left: 86px;
}

.voted-img .voted-img-box .box4 {
  background-color: #cbd5e1;
  position: absolute;
  left: 127px;
}

.voted-img .voted-img-box .box5 {
  background-color: #cbd5e1;
  position: absolute;
  left: 169px;
}

.voted-img .voted-img-box .user-icon {
  margin: 16px;
}

.voted-img .voted-img-box .user-icon.noVote {
  color: #398cd1;
}

.progress-box {
  display: flex;
  justify-content: center;
  margin: 21px 0px;
}

.progress-box .progressBar {
  width: 80px;
  height: 80px;
  background-color: var(--blue-600);
  border-radius: 51px;
}

.progress-box .progressBar .progress-icon {
  height: 50px !important;
  width: 50px !important;
  margin: 15px;
  color: var(--white-color);
}

.overview-btn-box {
  width: 100%;
  display: flex;
  gap: 13px;

}

.dic-NS-sale-details.overview {
  height: 60px;
  margin: 16px 0px;
  width: 100%;
}

.modalContentDone.overview {
  padding: 2px 124px;
}

.sub-headText {
  line-height: 1.5 !important;
  max-width: 67%;
}

.sub-headText.loan {
  max-width: 100% !important;
}

.sub-headText.overview {
  font-weight: 500;

}

.sub-headText.overviewText {
  color: #94A3B8;

}

.headIconBox {
  display: flex;
  gap: 12px;
}

.headIconBox .head-icon {
  color: #053863;
  margin: 12px;
}

.headIconBox .icon-box {
  background-color: #fff;
  height: 48px;
  width: 48px;
  border-radius: 12px;
}

.headIconBox .head-icon .right-box {
  margin-top: 0px;
  line-height: 1.5;
}

.headIconBox .head-icon .right-box .head-number {
  font-size: 16px;
  font-weight: 500;
}

.close-icon {
  color: #64748B;
  cursor: pointer;
}

.wallet-button.prev {
  background-color: var(--white-color) !important;
  color: var(--blue-900) !important;
}


.votedImg {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.votedImg .votedImgBox {
  display: flex;
  position: relative;
  margin: 0px 158px 0px 0px;
}

.votedImg .votedImgBox>div {
  background-color: #10B981;
  width: 40px;
  height: 40px;
  border-radius: 38px;
  position: absolute;
}

.votedImg .votedImgBox .vbox1 {
  left: 0;
}

.votedImg .votedImgBox .vbox2 {
  left: 29px;
}

.votedImg .votedImgBox .vbox3 {
  left: 58px;
}

.votedImg .votedImgBox .vbox4 {
  background-color: #CBD5E1;
  border: 2px solid #fff;
  left: 86px;
}

.votedImg .votedImgBox .vbox5 {
  left: 115px;
}

.votedImg .votedImgBox .voteUserIcon {
  margin: 11px 0px;
  font-weight: 500;
}

.overview-text-box.MuiBox-root.css-0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  justify-content: center;
  padding: 23px 2px;
}