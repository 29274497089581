.whyRace-list .item {
  border-radius: 12px;
  padding: 10px 12px;
  background-color: var(--blue-900);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}
.whyRace-list .item .MuiAvatar-root {
  background: linear-gradient(
    90deg,
    #b37a56 0%,
    #fbcba7 32.29%,
    #c38a79 71.35%,
    #945b3b 95.31%
  );
  border-radius: 12px;
}
.whyRace-list .item .MuiAvatar-root svg {
  color: var(--black-color);
}
.whyRace-list .item p {
  font-size: 16px;
  font-weight: 500;
}

/* End */

/* .wrTopNfts { */
/* height: 707px; */
/* margin-right: 32px; */
/* } */

.wrTopNfts .wrTopNfts-img {
  border-radius: 20px;
  margin-top: 64px;
  /* height: 546px; */
  /* height: 100%; */
  width: 100%;
}

/* .wrReasons-l {
    height: 707px;
    margin: 96px 0 70px 0;
} */

.wrReasons-l .wrReasons {
  /* width: 628px; */
  /* height: 104px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 52px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 32px 28px;
}

.wrReasons-l .wrReasons-l-box,
.wrReasons-l-box-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  gap: 12px;
  height: 69px;
  background: #05304a;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.wrReasons-l .wrReasons-l-box-1 {
  margin-top: 24px;
}

.wrReasons-l .wrReasons-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: 48px;
  height: 48px;
  background: #ede4c5;
  border-radius: 12px;
}

.wrReasons-l .wrReasons-icons-color {
  color: #998c61;
}

.wrReasons-l .wrReasons-texts {
  /* width: 528px; */
  /* height: 24px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
}
