/* ====================================
Foundation Home page css
====================================== */
.foundation-layout .main-page {
    margin-top: 0;
    overflow-x: hidden;
}

input:focus-visible {
    outline: none;
}

.fdn-subscribe-wrap {
    border-radius: 24px;
    background: rgba(3, 39, 68, 0.60);
    padding: 96px 20px 100px 20px;
    text-align: center;
    background-image: url('./../Assets/Images/fdn-subscribe-bg.png');
    background-position: center bottom;
    background-size: auto;
    background-repeat: no-repeat;
    backdrop-filter: blur(12.5px);
}

.fdn-homepage {
    background-color: #05131B;
    background-image: url('./../Assets/Images/foundation-home-bg.png');
    background-position: top center;
    background-size: auto;
    background-repeat: no-repeat;
}

/* Subscribe form */
.fdn-subscribe-input {
    width: 100%;
    max-width: 384px;
    position: relative;
    margin: 0 auto;
}

.fdn-subscribe-input span.icon {
    position: absolute;
    top: 16px;
    left: 8px;
    color: #9CA3AF;
}

.fdn-subscribe-input input.input-field {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    border: none;
    height: 52px;
    border: 1px solid #FFF;
    background: #FFF;
    padding: 12px 120px 12px 38px;
    border-radius: 15px;
}

.fdn-subscribe-input .btn-rounded {
    height: 36px;
    width: 100px;
    border-radius: 11px;
    font-weight: 500;
    position: absolute;
    right: 11px;
    top: 9px;
}

/* Subscribe form  End */
.fdn-subscribe-wrap h3.title {
    font-size: 22px;
    padding: 24px 0;
}

.title-chip-wrap .title-chip {
    border-radius: 14px;
    background: rgba(5, 56, 99, 0.60);
    padding: 5px 12px 5px 4px;
    display: inline-block;
    cursor: pointer;
}

.title-chip-wrap .title-chip span {
    background: var(--golden-gradient);
    font-size: 12px;
    font-weight: 500;
    color: #000;
    padding: 4px 14px;
    border-radius: 14px;
    margin-right: 15px;
}

.communityValues-wrap h4 {
    font-size: 36px;
    margin-bottom: 48px;
}

.communityValues-step .step {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    max-width: 110px;
    width: 100%;
    padding-top: 15px;
    position: relative;
    transition: 1s ease;
}

.communityValues-step .step:before {
    content: '';
    width: 100%;
    height: 3px;
    border-radius: 50px;
    background: var(--gray-500, #6B7280);
    position: absolute;
    top: 0;
    left: 0;
}

.communityValues-step .step.active:before {
    background: var(--golden-gradient);
}

.communityValues-wrap h3.title {
    font-size: 45px;
    margin-top: 67px;
}

.fdn-homeBanner-section h1.title {
    font-size: 64px;
    font-weight: 400;
    margin-top: 28px;
}

.fdn-homeBanner-section .subtitle {
    font-size: 22px;
    font-weight: 400;
    margin-top: 24px;
}

.fdn-welcomeFeatures-wrap {
    border-radius: 24px;
    background: rgba(3, 39, 68, 0.60);
    padding: 60px 50px;
    position: relative;
    z-index: 1;
}

.fdn-welcomeFeatures-wrap .wf-top-row h3 {
    font-size: 36px;
    font-weight: 400;
    max-width: 604px;
}

.fdn-welcomeFeatures-wrap .wf-top-row h4 {
    font-size: 22px;
    font-weight: 400;
    max-width: 415px;
}

.fdn-features-box {
    width: 100%;
    max-width: 555px;
    position: relative;
    padding: 30px 20px;
}

.fdn-features-box:before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: rgba(77, 130, 173, 0.30);
    backdrop-filter: blur(6px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.fdn-features-box * {
    z-index: 1;
}

.fdn-features-box h4 {
    font-size: 28px;
    margin-bottom: 10px;
}

.fdn-features-box p {
    font-size: 14px;
}

.fdn-features-box.one {
    margin-left: 30px;
}

.fdn-features-box.two {
    margin-top: 70px;
}

.fdn-welcomeFeatures-section {
    overflow: hidden;
}

/* ====================================
End Foundation Home page
======================================

 /* Tarole css - start*/
.foundationEconomy-page .AppStore-btn {
    height: 62px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
}

.foundationEconomy-page .AppStore-Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.foundationEconomy-page .AppStore-title {
    font-size: 12px;
    font-family: 'Inter', 'Rubik', sans-serif;
}

.foundationEconomy-page .AppStore-subtitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 22.5px;
    font-family: 'Inter', 'Rubik', sans-serif;
}

.EconomyBanner {
    background-image: url("../../src/Assets/Images/EconomyBanner.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
}

.foundationEconomy-page .asset-management-section {
    background: rgba(3, 39, 68, 0.60);
    backdrop-filter: blur(12.5px);
}

.foundationEconomy-page .asset-management-section .heading {
    text-align: center;
    font-size: 45px;
}

.foundationEconomy-page .asset-management-section .supporting-text {
    text-align: center;
    font-size: 18px;
    max-width: 788px;
    margin: 0 auto;
    margin-top: 20px;
}

.asset-management-section .Am-component {
    text-align: center;
}

.asset-management-section .Am-component .title {
    font-size: 24px;
    margin-top: 20px;
}

.asset-management-section .Am-component .desc {
    font-size: 18px;
    margin-top: 16px;
}

.asset-management-section .am-border-line {
    border-left: 1px solid #374151;
    border-right: 1px solid #374151;
    padding: 0px 15px;
    margin: 0px 15px;
}

.blockchain-journey-section {
    padding: 64px 0;
    align-items: center;
    gap: var(--6, 24px);
}

.blockchain-journey-section .blockchain-journey-section-right {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    flex: 1 0 0;
    border-radius: 24px;
    background: #032744;
    padding: 90px 30px 0 30px;
}

.blockchain-journey-section-right .economyPhoneBorder {
    border-top-left-radius: 55px;
    border-top-right-radius: 55px;
    padding: 16px 16px 0px 16px;
    background-color: #0F172A;
    width: 398px;
}

.blockchain-journey-section-right .economyPhoneBorder img {
    -webkit-box-shadow: 0px 3px 35px -7px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 3px 35px -7px rgba(255, 255, 255, 1);
    box-shadow: 0 0 20px 0px rgba(255, 255, 255, 0.2);
    border-radius: 48px 48px 0 0;
}

.blockchain-journey-section .blockchain-journey-section-left {
    display: flex;
    padding: 10px 24px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--6, 24px);
}

.blockchain-journey-section .blockchain-journey-section-left .app-icons {
    display: flex;
    padding: var(--0, 0px);
    align-items: center;
    gap: var(--8, 32px);
}

.blockchain-journey-section .blockchain-journey-section-left .app-name {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
}

.blockchain-journey-section .blockchain-journey-section-left .app-icon {
    display: flex;
    width: var(--16, 64px);
    height: var(--16, 64px);
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;
    border-radius: var(--rounded-lg, 8px);
    background: var(--Palette-Teal-800, #053863);
}

.read-points {
    display: flex;
    padding-top: var(--8, 32px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;
}

.FH-mega-menu {
    height: 92px;
    width: 200px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.community-section .lg-icon-thumbnail {
    min-height: 100%;
}

.community-section .lg-icon-thumbnail h5 {
    font-size: 36px;
    font-weight: 400;
}

.community-section .lg-icon-thumbnail .lg-content p {
    font-size: 18px;
}

.economy-main .section-heading h3.title {
    font-size: 64px;
}

.economy-main .section-heading .subtitle {
    font-size: 22px;
}

.foundationEconomy-page {
    background-color: #05131B;
}

.foundationGovernance-page {
    background-color: #05131B;
}

.gvn-bg-img-wrap {
    background-image: url('./../Assets/Images/governance-main-bg.png');
    background-position: center bottom;
    background-size: auto;
    background-repeat: no-repeat;
    backdrop-filter: blur(12.5px);
}

.gradient-text {
    background: linear-gradient(90deg, #945B3B 0%, #C38A79 32.29%, #FBCBA7 71.35%, #B37A56 95.31%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.house-card-wrap {
    border-radius: 24px;
    backdrop-filter: blur(12px);
    /* background-color: rgba(3, 39, 68, 0.60); */
    background-color: rgba(4 31 52);
    padding: 40px 18px;
    height: 100%;
}

.house-card-wrap .top-image {
    padding-bottom: 16px;
    width: 90px;
    height: auto;
}

.house-card-wrap .alert {
    background-color: #05131B;
    color: white;
    text-align: center;
    align-items: center;
}

.house-card-wrap .alert svg {
    color: white;
}

.exec-council-wrap {
    margin-top: 40px;
    border-radius: 24px;
    background-color: rgba(3, 39, 68, 0.60);
    padding: 40px 18px;
    height: 500px;
}


.gvn-council-wrap {
    border-radius: 24px;
    background: rgba(3, 39, 68, 0.60);
    padding: 70px 20px 70px 20px;
    text-align: center;
    background-image: url('./../Assets/Images/council-bg-img.png');
    background-position: center bottom;
    background-size: auto;
    background-repeat: no-repeat;
}

.fdn-header-wrap {
    transition: 0.2s ease;
    background-position: 0 0;
    animation: slideBackground 0.2s ease;
}

.opaque-navbar-bg {
    backdrop-filter: blur(5px);
    background: rgba(5, 19, 27, 0.8);
    border-radius: 0px 0px 12px 12px;

}

@keyframes rotateGlobe {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.globe-container {
    position: relative;
    height: 100vh;
}

.fdn-welcomeFeatures-section .globe-bg {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 64px;
    width: 550px;
    height: 550px;
    margin: 0 auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: rotateGlobe 10s linear infinite;
}

.fdn-welcomeFeatures-section .globe-bg-1 {
    background-image: url('../Assets//Images/globe-ellipse-1.svg');
    z-index: -10;
}

.fdn-welcomeFeatures-section .globe-bg-2 {
    background-image: url('../Assets//Images/globe-ellipse-2.svg');
    z-index: -11;
}

.fdn-welcomeFeatures-section .globe-bg-3 {
    background-image: url('../Assets//Images/globe-ellipse-3.svg');
    z-index: -12;
    /* Adjust the angle of rotation as needed */
}

.fdn-homeBanner-section .heading .btn-rounded .text-golden {}

@keyframes slideBackground {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 0 100%;
    }
}


.fnd-banner-img.MuiBox-root {
    background-color: #000;
    mix-blend-mode: lighten;

}

/* .fnd-banner-img .ex-banner-video{
    z-index: -3;
    top: 10px;
    width:100%;
    background-color: transparent;
    height: auto;
      z-index: -3;
      top: -72px;
  
  } */

@media only screen and (min-width: 1400px) {
    .fdn-homepage {
        background-size: 100% auto;
    }
}
@media only screen and (max-width: 1300px) {
    .fdn-features-box.one {
        margin-left: 0px;
    }
}
@media only screen and (max-width: 1199px) {

    .community-section .lg-icon-thumbnail h5 {
        font-size: 30px;
    }

    .community-section .lg-icon-thumbnail .lg-content p {
        font-size: 16px;
    }

    .section-heading2 {
        padding-top: 200px;
    }


}

@media only screen and (max-width: 900px) {
    .asset-management-section .am-border-line {
        border-left: none;
        border-right: none;
    }

    .fdn-welcomeFeatures-section .globe-bg {
        bottom: 220px;
    }

    .community-section .lg-icon-wrap .MuiAvatar-root img {
        max-width: 60px;
    }

    .community-section .lg-icon-thumbnail h5 {
        font-size: 24px;
        font-weight: 400;
    }

    .community-section .lg-icon-thumbnail .lg-content p {
        font-size: 14px;
    }

    .blockchain-journey-section .blockchain-journey-section-left {
        padding: 0;
    }

    .foundationEconomy-page .asset-management-section .heading {
        font-size: 34px;
    }
}

@media only screen and (max-width: 768px) {
    .section-heading2 {
        padding-top: 256px;
    }

    .fdn-welcomeFeatures-section .globe-bg {
        bottom: 251px;
    }
}

@media only screen and (max-width: 600px) {
    .communityValues-step {
        flex-wrap: wrap;
    }
   .communityValues-step .step {
        margin-bottom: 25px;
        margin-left: 0;
        margin-right: 30px;
    }
    .communityValues-wrap h3.title {
        margin-top: 10px;
    }
    .communityValues-step .step {
        font-size: 13px;
    }

    .fdn-welcomeFeatures-section {
        background: rgba(3, 39, 68, 0.60);
    }

    .fdn-welcomeFeatures-section .globe-bg {
        left: auto;
        right: 10px;
        bottom: 290px;
    }

    .section-heading2 {
        padding-top: 0px;
    }

    .gvn-council-wrap {
        padding: 30px 20px 225px 20px;
    }

    .communityValues-wrap h3.title {
        font-size: 32px;
    }

    .fdn-features-box.two {
        margin-top: 36px;
    }

    .fdn-homeBanner-section h1.title {
        font-size: 36px;
    }

    .fdn-homeBanner-section .subtitle {
        font-size: 16px;
    }

    .fdn-welcomeFeatures-wrap {
        padding: 32px 0;
        background: none;
    }

    .foundationEconomy-page .asset-management-section .heading {
        font-size: 28px;
    }

    .foundationEconomy-page .asset-management-section .supporting-text {
        font-size: 14px;
    }

    .asset-management-section .Am-component .title {
        font-size: 18px;
    }

    .asset-management-section .Am-component .desc {
        font-size: 14px;
    }

    .blockchain-journey-section .blockchain-journey-section-left .title {
        font-size: 26px;
    }

    .blockchain-journey-section .blockchain-journey-section-left .sub-title {
        font-size: 14px;
    }

    .blockchain-journey-section {
        padding-bottom: 0;
    }

    .read-points h5 {
        font-size: 14px;
    }

    .read-points {
        margin-top: 0px;
    }

    .economy-main .section-heading h3.title {
        font-size: 36px;
    }

    .economy-main .section-heading .subtitle {
        font-size: 16px;
    }

    .economy-main {
        margin-top: 241px;
    }

    .house-card-wrap .MuiAlert-message {
        font-size: 12px;
    }


    .gvn-bg-img-wrap {
        background-image: none;
    }

    .gvn-bg-img-wrap-mobile {
        background-image: url('./../Assets/Images/bg-img-govern-top.png');
        background-position: top left;
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-backdrop-filter: blur(12.5px);
        backdrop-filter: blur(12.5px);
    }

    .bg-bottom-mobile:after {
        content: "";
        background-image: url('./../Assets/Images/bg-img-govern-bottom.png');
        width: 100%;
        background-position: right;
        position: absolute;
        background-repeat: no-repeat;
        top: 71%;
        height: 701px;
        right: 0;
        z-index: 0;
    }


    .EconomyBanner {
        background-image: url("../../src/Assets/Images/Phones-economy-1.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
        margin-top: 64px;
    }


    .fdn-subscribe-input input.input-field {
        padding: 12px 80px 12px 38px;

    }
}


/* Foundation Terms */
.termsConditions-section-foundation .term-section-foundation h2,
.term-section-foundation h3 {
    text-align: center;
    font-size: 47px;
    margin: 13px 0px;
    font-weight: 400;
}

.termsConditions-section-foundation {
    margin-top: 64px;
}

.termsConditions-section-foundation p {
    font-size: 16px;
}

/* ===================================
End
==================================== */