/* .MuiContainer-root.MainContainer {
    max-width: 1440px;
} */

/* NavBar Css */
/* header.NavBar {
    background: var(--blue-900);
} */

/* header.NavBar .ToolBar {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px;
} */

/* .NavBar .left {
    display: flex;
    align-items: center;
}

.NavBar .right {
    display: flex;
    align-items: center;
} */

/* .NavBar .logo-wrap img {
    height: 40px;
    margin-right: 20px;
}

.NavBar a {
    color: var(--white-color);
    text-decoration: none;
    font-size: var(--font-base);
    font-weight: 500;
    text-transform: capitalize;
} */

/* .NavBar a:hover {
    color: var(--yellow-50);
    text-decoration: none;
}

button .NavBtn {
    border-radius: 100px;
    ;
    background: var(--yellow-50);
    color: var(--blue-900);
} */

/* button .NavBtn:hover {
    border-radius: 100px;
    ;
    background: none;
    color: var(--white-color);
} */

/* Header Css*/
.SliderBox {
    color: var(--white-color);
    margin-top: 64px;
}

.SliderBox h1 {
    font-size: 55px;
    font-weight: normal;
}

.SliderBox h1 strong {
    color: var(--yellow-50);
    font-weight: normal;
}

.SliderBox p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
}

/* CategoryBOx Css*/
.homeCategory-wrap .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
    background: none;
    box-shadow: none;
}

.homeCategory-wrap .MuiCardContent-root {
    padding: 10px;
    background: none;
    text-align: left;
}

.homeCategory-wrap h2 {
    font-size: var(--font-base);
    font-weight: 500;
    color: var(--white-color);
}

.homeCategory-wrap.MuiBox-root {
    padding: 0px;
}


.homeCategory-wrap .MuiCardMedia-root {
    border: solid 2px var(--blue-900);
}

.homeCategory-wrap .MuiCardMedia-root:hover {
    border: solid 2px var(--yellow-100);
    cursor: pointer;
}


/* card design css */


.CardBox.MuiBox-root {
    padding: 0;
    background: var(--blue-700);
    padding: 24px 16px;
    border-radius: 8px;
}

.SliderPanel {
    position: relative;
}

.TimerBox {
    position: absolute;
    bottom: -16px;
    border-radius: 8px;
    color: var(--white-color);
    background: var(--blue-900);
    border: "5px solid ";
    border-image-slice: 1;
    border-image-source: "linear-gradient(to left, red, orange)";
}

.TimerBox .MuiChip-root {
    background: var(--blue-900);
}

.CardSectionWrapper .carousel .slide img {
    border-radius: 12px;
    height: 284px;
}

.CardSectionWrapper .CardTitleBox {
    padding: 10px 0px;
}

.CardSectionWrapper .CardTitleBox h3,
h6 {
    margin: 0px;
    padding: 0px;
    color: var(--white-color);
}

.CardSectionWrapper .CardTitleBox h3 {
    font-size: 16px;
    font-weight: 400;

}

.CardSectionWrapper .CardTitleBox h6 {
    font-size: 12px;
    font-weight: 400;
    color: var(--blueGray-500);
    margin-top: 5px;
}

/* bottom avatar */
.AvtarBox .MuiCardHeader-root {
    padding: 10px 0px 0px 0px;
}

.AvtarBox .MuiCardHeader-title {
    color: var(--white-color);
    font-weight: 500;
}

.AvtarBox .MuiCardHeader-subheader {
    color: var(--blueGray-500);
    font-size: 12px;
}

.CarouselItem.MuiAvatar-root {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0px;
    margin: 0px;
}
.reachInvestors-section {
    background-color: var(--blue-500);
}
/* 
.MainContainer .ep-sec-home {
    margin-top: 64px;
} */


/* Changes start */

.upArrowBtn{
    position: fixed;
    width: 100%;
    left: 92%;
    bottom: 135px;
    height: 20px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
}
.upArrowBtn button{
    background-color: #074870;
}
/* Changes end */
