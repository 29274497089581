.modal-page {
  text-align: center;
}

.modal-header {
  display: flex;
  margin-top: 20px;
}

.modal-header .race-logo {
  width: 58px;
  height: 89px;
}

.modal-header .vote-imgBox {
  margin-left: 180px;
  margin-right: 180px;
}

.voted-img {
  text-align: center;
}

.text-1 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;

  margin-top: 60px;
}

.text-2 {
  text-align: center;
  font-size: 16px;
  font-weight: 400;

  margin-top: 29px;
}

.btn-box {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 40px;
  width: 780px;
  margin-left: 75px;
}

.btn-box .appr-btn {
  background-color: #10b981;
  width: 404px;
  height: 56px;
  border-radius: 16px;
  border: none;
}

.btn-box .rjct-btn {
  background-color: #ef4444;
  width: 404px;
  height: 56px;
  border-radius: 16px;
  margin-left: 10px;
  border: none;
}

.reason-Box {
  display: none;
  padding-bottom: 40px;
}

.reason-Box .reason-txt {
  text-align: center;
  font-size: 28px;
  font-weight: 400;

  /* margin-top: 130px; */
}

#outlined-multiline-flexible {
  color: #ffff;
}

.reason-Box .reason-txt-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.reason-Box .text-lft {
  margin-left: 115px;
}

.reason-Box .text-rght {
  margin-left: 293px;
}

#abdBox {
  display: none;
}

.approve-btn-detailBox {
  text-align: center;
}

.approve-btn-detailBox .submit-img {
  text-align: center;
}

.approve-btn-detailBox .rtoDBoard-btn {
  background-color: #83a4b8;
  border: none;
  width: 578px;
  border-radius: 16px;
  height: 48px;
  margin-top: 40px;
  color: #ffff;
}

.submit .text-3 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;

  margin-top: 60px;
}

.approve-btn-detailBox .text-1 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;

  margin-top: 60px;
}

.approve-btn-detailBox .text-2 {
  text-align: center;
  font-size: 16px;
  font-weight: 400;

  margin-top: 29px;
}

/* You have the option to fund the Junior Tranche of the project css starts from here*/
.ftjtoProject .text-1 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;

  margin-top: 60px;
}

.ftjtoProject .text-2 {
  text-align: center;
  font-size: 16px;
  font-weight: 400;

  margin-top: 29px;
}

.loanDetail-form {
  margin-top: 30px;
  width: 800px;
  text-align: center;
  margin-left: 66px;
  padding-bottom: 30px;
}

.loanDetail-form .lA-txt-field {
  display: flex;
  background-color: #ffff;
  border-radius: 8px;
  margin-top: 14px;
}

.loanDetail-form .lA-txt-field .stPi-stIcon {
  color: aquamarine;
}

.loanDetail-form .lA-txt-field .plusIcon {
  height: 44px;
  margin-top: 5px;
  margin-left: 4px;
}

.loanDetail-form .lA-txt-field .minusIcon {
  height: 44px;
  margin-top: 5px;
  margin-right: 4px;
}

.loanDetail-form .ir-txt-field {
  background-color: #ffff;
  border-radius: 8px;
  margin-top: 14px;
}

.loanDetail-form .lD-txt-field {
  background-color: #ffff;
  border-radius: 8px;
  margin-top: 14px;
}

.ftjtoProject .back-btn {
  width: -webkit-fill-available;
  margin-top: 24px;
  height: 46px;
  border-radius: 12px;
}

.ftjtoProject .back-btn .back-icon {
  margin-top: -8px;
}

.ftjtoProject .sA-btn .sA-icon {
  margin-top: -8px;
}

.ftjtoProject .sA-btn {
  width: -webkit-fill-available;
  margin-top: 24px;
  height: 46px;
  border-radius: 12px;
  background-color: #83a4b8;
  color: white;
}

/*Review Loan details css starts from here*/
.rLDetails .text-1 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;

  margin-top: 60px;
}

.rLDetails .text-2 {
  text-align: center;
  font-size: 16px;
  font-weight: 400;

  margin-top: 29px;
}

.rLDetails .rLDetail-form .detail-box {
  background-color: #064165;
  border-radius: 8px;
  display: flex;
}