.MuiModal-root.kyc-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiModal-root.kyc-modal .kyc-modal-body {
  width: 100%;
  max-width: 1237px;
}

.kycIpi-col-left {
  background: var(--blue-900);
  border-radius: 24px 0px 0px 24px;
}

.kycIpi-col-right {
  background: var(--white-color);
  border-radius: 0px 24px 24px 0px;
}

.kycIpi-left {
  padding: 54px 40px;
  height: 100%;
  max-height: 85vh;
  overflow: auto;
}

.kyc-modal-body:focus-visible {
  outline: none;
}

/* KYC Left Section */
.kyc-step-nav-item .kyc-icon {
  color: var(--white-color);
  border-radius: 40px;
  margin-right: 17px;
  background: var(--blueGray-400);
  position: relative;
  overflow: hidden;
}

.kyc-step-nav-item .kyc-icon:before {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-image: url(../../Assets/Images/check-white-icon.png);
  background-color: var(--green-500);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.kyc-step-nav-item.kyc-completed .kyc-icon:before {
  display: flex;
}

.kyc-step-nav-item .kyc-heading .kyc-title {
  text-align: left;
  color: var(--blueGray-400);
  letter-spacing: 0.4px;
}

.kyc-step-nav-item .kyc-arrow {
  display: none;
}

.kyc-step-nav-item.kyc-active .kyc-heading .kyc-title {
  color: var(--blue-200);
}

.kyc-step-nav-item.kyc-active .kyc-icon {
  background: var(--blue-200);
}

.kyc-step-nav-item.kyc-active .kyc-arrow {
  display: block;
}

.kyc-step-nav-item.kyc-completed .kyc-heading .kyc-title {
  color: var(--green-500);
}

.kycPi-tab-box button.MuiButtonBase-root {
  max-width: 100%;
}

/* KYC Right Section */

.kycInvesting-type-wrap .kycInvesting-type-btn {
  border-radius: 12px;
  background: var(--white-color);
  cursor: pointer;
  max-width: 464px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.kycInvesting-type-wrap .kycInvesting-type-btn:hover {
  background: var(--white-color);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.kycInvesting-type-wrap .kycInvesting-type-btn .kyc-icon {
  border-radius: 12px;
}

.kycInvesting-type-wrap .kycInvesting-type-btn .kyc-icon svg {
  color: var(--yellow-700);
}

.kyc-individual-step-wrap {
  color: var(--blueGray-900);
  padding: 34px 45px;
  overflow: auto;
  height: 100%;
  max-height: 85vh;
  margin-right: 20px;
  padding-right: 25px;
}

.kyc-individual-step-wrap .kyc-individual-step {
  padding-right: 45px;
}

.kyc-individual-step-wrap .navigation {
  margin-bottom: 28px;
}

.kyc-individual-step-wrap.step-02 .MuiIconButton-root svg,
.kyc-individual-step-wrap .navigation svg {
  color: var(--blue-600);
}

.kyc-individual-step-wrap .heading {
  max-width: 470px;
}

.kyc-individual-step-wrap .heading .title {
  color: var(--black-color);
  font-size: 32px;
}

.kyc-individual-step-wrap .heading .subtitle {
  color: var(--black-color);
  font-size: 18px;
  margin-top: 15px;
}

.otp-input-group>.MuiFormControl-root {
  width: 50px;
}

.otp-input-group>.MuiFormControl-root input {
  text-align: center;
}

.kyc-step-progress .MuiLinearProgress-root {
  background-color: var(--blueGray-200);
}

.kyc-step-progress .MuiLinearProgress-bar {
  background-color: var(--blue-600);
}

.kyc-individual-step .kyc-uploadDoc {
  border-radius: 12px;
  background-color: var(--blueGray-100);
}

.modal-body.bgBlack {
  background-color: #05131B;
}

.modal-body.bgBlack .MuiFormControl-root input.MuiInputBase-input, input.form-control {
  color: #ffffff;
}
.modal-body.bgBlack .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
  color: #ffffff;
}
.modal-body.bgBlack .MuiFormControl-root label.MuiFormLabel-filled{
  color: #ffffff;
}
/* =================================
End
=================================== */