.staticProposerModel .MuiFormControl-root input.MuiInputBase-input {
  color: white;
}

.css-1qfzphi {
  padding: 0px 32px !important;
}

.css-1a5adae {
  padding-top: 0px !important;
}

.investor-modal-container::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}

.investor-modal-container:hover::-webkit-scrollbar-thumb {
  background: #555;
}

.dashedTextField .MuiInputBase-input {
  color: #fff !important;
}