/* =======================================
Small Desktop 
========================================*/
@media only screen and (max-width: 1299px) {

}

/* =======================================
Ipad
========================================*/
@media only screen and (max-width: 1199px) {
   .container {
      padding-left: 16px;
      padding-right: 16px;
   }
   .section-heading h3.title {
      font-size: 36px;
   }
   .section-heading h3.title br {
      display: none;
   }
   .section-heading .subtitle {
      font-size: 16px;
   }

   .sp-moreBenefits-wrap .lg-icon-thumbnail .lg-content h5 {
      font-size: 28px;
   }

   .lg-icon-thumbnail .lg-content p {
      font-size: 16px;
   }

   .lg-icon-wrap .MuiAvatar-root {
      width: 70px;
      height: 70px;
   }
   .lg-icon-thumbnail .lg-content h5 {
      font-size: 20px;
   }
   .lg-icon-wrap .MuiAvatar-root img {
      max-width: 40px;
   }
   div .staticServiceProviders-section {
      background-position: center right;
   }
   .staticServiceProviders-section:before {
      content: '';
      background: rgba(0,0,0,0.4);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 0;
   }
   .staticServiceProviders-section .staticServiceProviders-wrap {
      position: relative;
      z-index: 1;
   }
}

/* =======================================
Ipad Mini
========================================*/
@media only screen and (max-width: 899px) {
   
   .pj-getStart-section {
      display: flex;
      flex-direction: inherit;
      justify-content: inherit;
      align-items: inherit;
      padding: 40px 32px;
   }
   .aboutFutureInvesting-section:before {
      content: '';
      background: rgba(0,0,0,0.4);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 0;
   }
   .aboutFutureInvesting-wrap  {
      position: relative;
      z-index: 1;
   }
}

/* =======================================
Mobile
========================================*/
@media only screen and (max-width: 599px) {
   div .font-36 {
      font-size: 24px;
   }
   div .font-18 {
      font-size: 16px;
   }
   .section-heading h3.title {
      font-size: 30px;
   }

   .section-heading .subtitle {
      font-size: 14px;
   }

   .hc-thumbnail .hc-img-wrap .hc-img {
      height: 100px;
   }
   .faqCard-detailBox div .font-22 {
      font-size: 16px;
   }
   .faqCard-detailBox div .font-18 {
      font-size: 14px;
   } 
   .form-group {
      margin-bottom: 20px;
   }
   .pj-getStart-section .section-heading h3.title {
      font-size: 30px;
   }
   .pj-getStart-section {
      padding: 30px 16px;
   }
   .reach-client-detailBox .title-txt {
      font-size: 18px;
   }
   .reach-client-detailBox {
      margin-left: 0;
   }
   .faqCard-detailBox .NS-sale-details-icon-btn {
      padding: 4px;
  }
   .lg-icon-thumbnail .lg-content p {
      font-size: 14px;
   }
}

@media only screen and (max-width: 399px) {
   .homeCategory-wrap .hc-thumbnail-column {
      flex-basis: 50%;
      max-width: 50%;
   }
}

@media only screen and (max-width: 299px) {
   .homeCategory-wrap .hc-thumbnail-column {
      flex-basis: 100%;
      max-width: 100%;
   }

   .hc-thumbnail .hc-img-wrap .hc-img {
      height: 135px;
   }
}

/* =======================================
End 
========================================*/