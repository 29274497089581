.fnd-footer {
    background: #05131B;
}

.fnd-footer a {
    color: #9CA3AF;
    -webkit-transition: color 0.25s ease;
    transition: color 0.25s ease;
}

.fnd-footer a:hover {
    color: var(--white-color);
}

.fnd-footer-menu .footer-colum .title {
    color: var(--white-color);
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.1px;
    margin-bottom: 28px;
}

.fnd-footer-menu .ftr-menus .menu-item {
    margin-bottom: 28px;
    text-align: left;
}

.fnd-footer-menu .ftr-menus .menu-item a {
    font-size: var(--font-14);
    text-align: center;
    letter-spacing: 0.25px;
}

@media screen and (max-width: 768px) {
    .fnd-footer-menu .ftr-menus .menu-item {
        text-align: left;
    }
}

@media screen and (max-width: 768px) {
    .fnd-footer-menu .footer-colum .title {
        text-align: left;
    }
}


.fnd-footer-copyright {
    border-top: solid 1px #9CA3AF;
    padding: 30px 0;
}

.fnd-footer-copyright .des {
    color: #9CA3AF;
    font-size: 16px;
    text-align: center;
}

.fnd-footer-copyright .tac {
    text-align: center;
}

.footer-about p {
    font-size: 16px;
    font-style: normal;
    color: #9CA3AF;
    margin: 18px 0;
}

.ftr-visitBridge {
    background: rgba(5, 56, 99, 0.60);
    border-radius: 8px;
    padding: 10px 10px 10px 18px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ftr-visitBridge>span {
    margin-right: 5px;
}
.fnd-footer-menu .footer-explore-mobile {
    display: none;
}
.ftra-copyright-logo {
    display: none;
    margin-bottom: 20px;
}

.contactus-popup.foundation .MuiDialog-container .MuiPaper-root {
    width: 100%;
    max-width: 580px;
    border-radius: 20px;
    background: rgba(3, 39, 68, 0.4);
    backdrop-filter: blur(12px);
    padding: var(--12, 40px) var(--9, 32px);
    text-align: center;
  }
.contactus-popup.foundation .title {
    font-size: clamp(24px, 3vw, 32px);
}
.contactus-popup.foundation .contact-us-modal-text {
    font-size: clamp(14px, 3vw, 16px);
    color: #ffffff;
}
.contactus-popup.foundation .icon {
    font-size: 28px;
    color: #ffffff;
}
.contactus-popup.foundation .telegram-icon.icon {
    background-color: #1292d1;
    width: 29px;
    height: 29px;
    border-radius: 50%;
}
.contactus-popup.foundation .telegram-icon.icon svg {
    width: 20px;
}
button.btn-theme-primary {
    background-color: #053863;
    color: #9CA3AF;
    font-size: 16px;
    text-transform: capitalize;
    padding: 5px 15px;
    border-radius: 8px;
}
button.btn-theme-primary:hover {
    background-color: #053863;
    color: #9CA3AF;
}

@media only screen and (max-width: 899px) {
    .fnd-footer-menu .footer-explore-mobile {
        display: block;
    }
    .fnd-footer-menu .footer-about {
        display: none;
    }
}
@media only screen and (max-width: 599px) {
    .fnd-footer {
        background: #111928;
        margin-top: 50px;
    }
    .ftra-copyright-logo {
        display: block;
    }
    .fnd-footer-copyright .des {
        font-size: 14px;
    }
}
/* .ftr-termsPrivacy-menus .menu-item a {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

@media only screen and (max-width: 560px) {
    .ftr-termsPrivacy-menus .menu-item {
        width: 50%;
        margin: 3px 0;
        text-align: center;
    }
} */