/* .staticServiceProviders-section {
  background-image: url("../Assets/Images/StaticLanding/staticServiceProviders-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
} */
.staticServiceProviders-section .heading .title {
  font-size: 45px;
}
.staticServiceProviders-section .contant-box {
  border-radius: 24px;
  background: var(--blue-700);
  padding: 24px;
  min-height: 150px;
  height: 100%;
}
.staticServiceProviders-section .contant-box p {
  font-size: 28px;
}
.headline .headText {
  text-align: center;
  color: #fff;
}

.sp-txt {
  font-size: 18px;
}

.reach-client-detailBox {
  display: flex;
  margin-top: 18px;
  margin-left: 24px;
}

.reach-client-detailBox .details {
  margin-left: 16px;
}

.reach-client-detailBox .title-txt {
  font-size: 22px;
}

.reach-client-detailBox .infoTxt {
  font-size: 12px;
}

.staticProposeProject-form {
  background-color: #fff;
  /* margin-left: 16px; */
  /* width: 550px; */
}

.staticProposeProject-wrap .pop-rightCard-headtxt {
  font-size: 36px;
  margin-left: 48px;
}

/* .staticProposeProject-wrap .pop-rightCard-detailBox .questionTextBox {
  display: flex;
} */

.faq-card-box .faqCard-detailBox .questionTextBox {
  display: flex;
}
.faq-card-box
  .faqCard-detailBox
  .questionTextBox
  .buttonIcon-box
  .accordian-info {
  background-color: #032744;
  color: #fff;
  padding: 10px 0;
}

.faq-card-box {
  background-color: #032744;
  border-radius: 16px;
  min-height: 100%;
  padding: 62px 48px;
}

.faq-card-box .faq-txt {
  font-size: 36px;
}
.faq-card-box .MuiAccordionSummary-content .title {
  color: var(--blue-50);
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  padding-right: 10px;
}
.faq-card-box .accordian-info.Mui-expanded .MuiAccordionSummary-content .title {
  color: var(--blue-200);
}
.faq-card-box .MuiAccordionDetails-root {
  padding-top: 4px;
}
.faq-card-box .MuiAccordionDetails-root .description {
  color: var(--blue-200);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.accordian-info
  .MuiAccordionSummary-expandIconWrapper
  .NS-sale-details-icon-btn {
  background-color: #fff;
  border-radius: 12px;
  width: 56px;
  height: 56px;
}
.Mui-expanded.accordian-info {
  color: var(--blue-300) !important;
}

.accordian-info
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded
  .MuiIconButton-sizeMedium.NS-sale-details-icon-btn {
  background: var(--blue-300);
}
.accordian-info.MuiAccordion-root:before {
  background-color: #d9dbe9;
}
.accordian-info.MuiAccordion-root.Mui-expanded:before {
  opacity: 1;
}

.bodyText {
  margin-top: 60px;
}
/* Investors Meet  */
.investorsMeet-wrap {
  padding: 60px 32px 0px;
  background: var(--blue-900);
  border-radius: 24px;
}
.sp-moreBenefits-wrap .lg-icon-wrap .MuiAvatar-root {
  border-radius: 12px;
}
.aboutFutureInvesting-section {
  background-image: url("../Assets/Images/aboutFutureInvesting-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.sp-moreBenefits-section .lg-icon-thumbnail .lg-content h5 {
  font-size: 36px;
  font-weight: 400;
}
.faq-details-text {
  padding: 8px 16px 16px;
  text-align: left;
}

/* .lg-icon-thumbnail.multi-fund .lg-icon-wrap svg  */

.roles_select .MuiInputBase-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined ,.roles_select .MuiInputBase-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.MuiSelect-iconOpen {
  color: var(--blue-600);

}



/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--blue-800);
} */

/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.MuiSelect-iconOpen.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--blue-800);
} */

.lg-icon-thumbnail .lg-content h5 {
  font-weight: 500;
}

.pj-getStart-section .section-heading h3.title {
  font-size: 36px;
}

.rc-vergeOfWorld-section .pj-getStart-section .pj-getStart-wrap .sp-txt {
  font-size: 18px;
}

.staticFaq-section
  .faq-card-box
  .faqCard-detailBox
  .questionTextBox
  .buttonIcon-box
  .accordian-info {
  padding: 25px 0;
}
.staticFaq-section
  .faq-card-box
  .accordian-info.Mui-expanded
  .MuiAccordionSummary-content
  .title {
  color: var(--blue-50);
}
.staticFaq-section .faq-card-box .MuiAccordionDetails-root .description {
  color: var(--blueGray-400);
}
.tc-list .tc-item {
  margin-bottom: 28px;
}
.tc-list .tc-item .tc-title {
  color: var(--white-color);
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 13px;
}
.tc-list .tc-item .tc-description {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.6;
}

.left-img .mobileimgcontainer {
  max-width: 412px;
  box-shadow: 10px 2px 20px 0px #0F172A inset, -2px -10px 20px 0px rgba(15, 23, 42, 0.25) inset, 40px 60px 80px 0px rgba(62, 68, 128, 0.25);
  padding: 15px 15px 0px 15px;
  background: #7BB9ED;
  border-radius: 50px 50px 0 0;
}

/* =============================================== 
Responsive 
================================================*/
@media only screen and (max-width: 1199px) {
  .staticServiceProviders-section .contant-box p {
    font-size: 22px;
  }
  .faq-card-box {
    padding: 40px 26px;
  }
  .accordian-info
    .MuiAccordionSummary-expandIconWrapper
    .NS-sale-details-icon-btn {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 899px) {
  .investorsMeet-wrap {
    padding: 32px;
  }
  .investorsMeet-wrap .left-img {
    display: none;
  }
}
@media only screen and (max-width: 599px) {
  .staticServiceProviders-section .contant-box {
    min-height: inherit;
    padding: 16px;
  }
  .staticServiceProviders-section .contant-box p {
    font-size: 16px;
  }
  div .faq-card-box .faq-txt {
    font-size: 30px;
  }
  .faq-card-box .MuiAccordionSummary-content .title {
    font-size: 16px;
    line-height: 22px;
  }
  .faq-card-box .MuiAccordionDetails-root .description {
    font-size: 14px;
    line-height: 22px;
  }
  .faq-card-box {
    padding: 24px 16px;
  }
  .staticFaq-section
    .faq-card-box
    .faqCard-detailBox
    .questionTextBox
    .buttonIcon-box
    .accordian-info {
    padding: 10px 0;
  }
  .tc-list .tc-item .tc-title {
    font-size: 20px;
  }
  .tc-list .tc-item .tc-description {
    font-size: 13px;
  }
  .sp-moreBenefits-section .lg-icon-thumbnail .lg-content h5 {
    font-size: 24px;
  }
  .faq-card-box .MuiAccordionDetails-root {
    padding-top: 4px;
    padding: 4px 0px 8px;
  }
  .faq-card-box .MuiAccordionSummary-root {
    padding: 0;
  }
}

/* Static Terms */
.termsConditions-section .term-section h2 {
  text-align: center;
  font-size: 57px;
}

.termsConditions-section .term-section p:nth-child(even) {
  font-size: 28px;
}

.termsConditions-section .term-section p:nth-child(odd) {
  font-size: 16px;
}

/* Static Privacy policy */
.privacyPage-section .privacy-section h2 {
  text-align: center;
  font-size: 57px;
}

.privacyPage-section .privacy-section p {
  font-size: 16px;
}