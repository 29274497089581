.heading-text{
    
}

.icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: #1771BD;
}

.icon-box .icon {
    font-size: 32px;
    color: #C5E4FF;
    line-height: 64px;
}

.kyc-modal.blurBackground-modal .MuiBackdrop-root.MuiModal-backdrop {
    backdrop-filter: blur(5px); /* Adjust the blur value as needed */
}

.wallet-button.loan {
    /* max-width: 15px; */
    /* border-radius: 16px; */
}

.waitlistpopup{
 margin-top: 50px !important;
}

.selectRole{
display: flex;
width: 188px;
height: 137px;
padding: 24px;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
flex-shrink: 0;
border-radius: 24px;
}

.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip {
    width: 175px;
    transform-origin: center bottom;
    margin-bottom: 14px;
    text-align: center;
    font-family: "Rubik";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    background-color: #053863;
    border-radius: 10px;
}
