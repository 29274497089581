.main-header-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 12px 0;
  background-color: black;
  height: 64px;
  z-index: 99;
}
.dashborad-header .main-header-wrap .container {
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
.main-header .left .logo {
  width: 26px;
  margin-right: 50px;
}

.main-header .left .logo a {
  display: block;
  line-height: 0;
}

.headerLeft-menu .menu-item a {
  font-size: var(--font-base);
  color: #fff;
  font-weight: 400;
}

.main-header-wrap .wallet-btn {
  font-size: var(--font-base);
  font-weight: 400;
  color: #fff;
  background: var(--blue-400);
  border-radius: 40px;
  height: 40px;
  padding: 6px 18px;
  text-transform: none;
}

.main-header-wrap .wallet-btn:hover {
  color: #fff;
  background: var(--blue-400);
}

.main-header-wrap .wallet-btn span.icon svg {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 20px;
}

.main-header-wrap .wallet-btn .text {
  padding: 0 10px;
  color: #ffffff;
}

.main-header-wrap .notification svg {
  font-size: 24px;
  color: var(--blue-50);
}

.main-header-wrap .userStatus {
  position: relative;
}

.main-header-wrap .userStatus .insideBadge {
  width: 16px;
  height: 16px;
  background: var(--green-500);
  border: 4px solid #eff6ff;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.main-header-wrap .notification .MuiBadge-badge {
  font-size: 11px;
  font-family: var(--body-fontFamily);
  font-weight: 400;
  top: 6px;
  right: 2px;
  background-color: var(--red-600);
  min-width: 16px;
  padding: 0 4px;
  height: 16px;
}

.timelapse-badge .MuiBadge-badge {
  min-width: 8px !important;
  height: 8px !important;
}

.main-header-wrap .profileMenu-btn {
  color: var(--black-color);
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 10px;
  background-color: var(--blue-50);
}

.main-header-wrap .profileMenu-btn:hover {
  background-color: var(--blue-50);
}

.main-header-wrap .profileMenu-btn svg {
  font-size: 24px;
  color: var(--black-color);
}

.headerRight-menu {
  margin-left: 32px;
}

.headerRight-menu .menu-item a {
  font-size: var(--font-base);
  color: #fff;
  font-weight: 400;
}

.dropdown-menu.InvesterHeader-dropdown .MuiMenu-paper.MuiPopover-paper {
  margin-top: 16px;
}

/* Mega Menu Design  */

.mega-menu .MuiPaper-root {
  background-color: var(--blue-800);
}

.mega-menu.InvesterHeader-dropdown .MuiMenu-paper.MuiPopover-paper {
  margin-top: 4px;
}

.mega-menu .MuiPaper-root .MuiMenu-list {
  padding: 0;
}

.mega-menu .mega-menu-box {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
}

.mega-menu .mega-menu-item > a {
  display: block;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  height: 100%;
}

.mega-menu .mega-menu-item > a:hover,
.mega-menu .mega-menu-item > a.active {
  background: var(--blue-600);
}

.mega-menu-box .icon {
  margin-right: 13px;
}
.mega-menu-box .icon .MuiAvatar-root {
  width: 50px;
  height: 50px;
  border-radius: 0;
}
.mega-menu-box .title {
  color: var(--white-color);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.1px;
}

.mega-menu-box .subtitle {
  color: var(--white-color);
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-top: 8px;
}

/* End Mega menu */

.InvesterHeader-dropdown .MuiPaper-root .MuiList-root {
  padding: 0;
}

/*================================= 
End
=================================*/
