.MuiTypography-root.MuiTypography-body1.center-headText.css-13o5jai-MuiTypography-root {
  text-align: center;
  color: var(--palette-white-900, #fff);
  text-align: center;
  font-family: Rubik;
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  margin-top: 100px;
}

.MuiTypography-root.MuiTypography-body1.Title-txt.css-13o5jai-MuiTypography-root {
  margin-left: 50px;
  margin-top: 40px;
  color: var(--palette-white-900, #fff);
  /* Material typography/headline3 */
  font-family: Rubik;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 128.571% */
}

.MuiTypography-root.MuiTypography-body1.info-txt.css-13o5jai-MuiTypography-root {
  margin-left: 50px;
  margin-top: 20px;
  margin-right: 50px;
  color: var(--palette-white-900, #fff);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
