/* Start  */

.projectDetails-textEditor * {
  color: var(--white-color) !important;
}

.pd-titleBar .title {
  color: var(--white-color);
  font-size: 32px;
  font-weight: 400;
}

.pd-titleBar .subtitle {
  color: var(--blueGray-400);
  font-size: 14px;
  margin-top: 8px;
}

.projectDetails-left {
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 20px 24px;
  height: 100%;
}

/* .projectDetails-wrap .tage-wrap .square-chip.MuiChip-filled {
  max-width: 95px;
} */

/* Project details right sidebar */
.projectDetails-right hr.MuiDivider-root {
  border-color: var(--blueGray-700);
}

.pd-sidebar-heading .title {
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0;
}

.MuiCard-root.pd-action-card {
  color: var(--white-color);
  border-radius: 12px;
  background: var(--blue-800);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  margin-bottom: 4px;
}

.pd-action-card .MuiCardHeader-content .MuiCardHeader-title,
.pd-sidebar-documents .pd-action-card .MuiCardHeader-content .MuiCardHeader-subheader {
  color: var(--white-color);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.pd-action-card .MuiCardHeader-content .MuiCardHeader-subheader,
.pd-sidebar-documents .pd-action-card .MuiCardHeader-content .MuiCardHeader-title {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.pd-action-card .MuiCardHeader-action {
  margin: 0;
}

.pd-action-card .MuiCardHeader-action .MuiChip-root {
  color: var(--text-white);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  padding: 10px 12px;
  background-color: var(--blue-500);
  border-radius: 40px;
  height: 40px;
}

.pd-action-card .MuiCardHeader-root {
  padding: 12px;
  cursor: pointer;
}

.pd-sidebar-detailsList .detailsList {
  padding: 8px 16px;
  margin-bottom: 4px;
}

.pd-sidebar-detailsList .detailsListCheckBox {
  padding: 0px 16px;
}

.pd-sidebar-detailsList .detailsList .title {
  color: var(--white-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.pd-sidebar-detailsList .detailsListCheckBox .title {
  color: var(--white-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.pd-sidebar-detailsList .detailsList .subtitle {
  color: var(--blueGray-400);
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.pd-sidebar-editMap .edit-map {
  border-radius: 12px;
  overflow: hidden;
}

.dic-alert-box {
  margin-top: 15px;
}

.dic-alert-box svg {
  margin-top: 18px;
  background-color: none;
  color: #ffffff;
}

.review-complete {
  margin: 22px 8px;
  background-color: #10B981 !important;
  border-radius: 10px !important;
}

/* ================================
End 
=================================*/

/* Details thumbnail Slider  */
.details-thumbnails-slider .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left {
  width: 190px;
}

.details-thumbnails-slider .image-gallery-thumbnail {
  width: 190px;
  height: 127px;
  border: 2px solid transparent;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
}

.details-thumbnails-slider .image-gallery-thumbnail.active,
.details-thumbnails-slider .image-gallery-thumbnail:focus {
  border: 2px solid var(--blue-500);
}

@media (hover: hover) and (pointer: fine) {
  .details-thumbnails-slider .image-gallery-thumbnail:hover {
    border: 2px solid var(--blue-500);
  }
}

.details-thumbnails-slider .image-gallery-slide-wrapper.image-gallery-thumbnails-left {
  width: calc(100% - 210px);
  margin-left: 10px;
}

.details-thumbnails-slider .image-gallery-slide-wrapper .image-gallery-slides {
  border-radius: 18px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
}

.details-thumbnails-slider .fullscreen .image-gallery-slide-wrapper .image-gallery-slides {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-thumbnails-slider .image-gallery-slide-wrapper .image-gallery-index {
  font-size: 14px;
  font-weight: 500;
  background-color: var(--blueGray-700);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 13px 20px;
  border-radius: 20px;
  min-width: 78px;
  text-align: center;
  right: 10px;
  top: auto;
  bottom: 15px;
}

.details-thumbnails-slider .image-gallery-slide-wrapper .image-gallery-fullscreen-button {
  right: auto;
  left: 0;
  bottom: 0;
}

/* End Details thumbnail Slider  */

.pdPage-main {
  /* background-color: #13202d; */
  display: flex;
  justify-content: space-between;
  position: relative;
  /* top: 60px; */
  margin-bottom: 20px;
}

.pdPage-main-slides {
  background-color: #032744;
  border-radius: 16px;
  /* margin: 10px; */
  height: 100%;
}

.pdPage-main-slides1 {
  display: flex;
  justify-content: space-evenly;
}

.pdPage-main .imageTitle {
  font-weight: 400;
  color: #ffffff;
  font-size: 22px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  height: 40px;
}

.pdPage-main .imageTitle-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5%;
}

.pdPage-main .custom-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 30px; */
}

.pdPage-main .pdCd {
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
}

.pdPage-main .sale {
  width: 54px;
  height: 32px;
  font-weight: 500;
  font-size: var(--font-base);
  flex-direction: column;
  padding: 0px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #13202d;
  border-radius: 8px;
}

.pdPage-main .real-state {
  width: 102px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  font-weight: 500;
  font-size: var(--font-base);
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  border-radius: 8px;
  color: #ffffff;
  background: #13202d;
}

.pdPage-main .multi {
  width: 110px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  font-weight: 500;
  font-size: var(--font-base);
  border-radius: 8px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #13202d;
}

.pdPage-main .editButtonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 48px;
  height: 48px;
  background: #83a4b8;
  border-radius: 12px;
}

.pdPage-main .viewButtonIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 48px;
  height: 48px;
  background: #83a4b8;
  border-radius: 12px;
}

.pdPage-main .textElementTitle {
  letter-spacing: 0.25px;
  color: #94a3b8;
}

.pdPage-main .navigationOverview {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 12px 16px 16px;
  gap: 12px;
  width: 166px;
  height: 56px;
  background: #205a7e;
  border-radius: 100px;
  margin-top: 20px;
}

.pdPage-main .navigationOverview1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 12px 16px 16px;
  gap: 12px;

  width: 166px;
  height: 56px;
  background: #002942;
  border-radius: 100px;
  flex: none;
  margin-top: 20px;
}

.pdPage-main .navigationOverview .overview,
.pdPage-main .navigationOverview1 .overview1 {
  width: 78px;
  height: 20px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #ffffff;
  flex-grow: 1;
}

.pdPage-main .overviewDesc {
  height: 32px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-right: 2%;
}

.pdPage-main .mapRadius {
  border-radius: 10px;
}

.pdPage-main .overviewText {
  font-weight: 400;
  font-size: var(--font-base);
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 24px;
  margin-right: 2%;
}

.pdPage-main .featuresDesc {
  /* width: 647px; */
  height: 32px;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 24px;
  margin-right: 2%;
}

.pdPage-main .ListFeatures {
  /* width: 647px ; */
  height: 140px;
  font-weight: 400;
  font-size: var(--font-base);
  align-items: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 24px;
  margin-right: 2%;
}

.pdPage-main .head1 {
  margin: 3;
  background-color: #13202d;
}

.pdPage-main .placeholder4Img {
  width: 740px;
  height: 480px;
  margin-top: 15px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.pdPage-main .placeholder1Img {
  width: 220px;
  margin-top: 15px;
  height: 150px;
  /* margin-bottom: 10px; */
  border-radius: 15px;
}

.projectDocumentsModal .pg-viewer-wrapper {
  overflow: hidden;
}

.projectDocumentsModal.MuiModal-root>.MuiBox-root {
  width: 735px;
  max-width: 90%;
  height: 85%;
  background: var(--blue-900);
  border-radius: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  border: none;
  padding: 32px;
}

.projectDocumentsModal.MuiModal-root>.MuiBox-root:focus-visible {
  border: none;
}

.pdPage-main .placeholder2Img {
  width: 220px;
  height: 150px;
  /* margin-bottom: 10px; */
  border-radius: 15px;
}

.pdPage-main .placeholder3Img {
  width: 220px;
  height: 150px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.rightArrowSign {
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  margin-top: 24px;
}

.arrowHeader {
  /* top: 64px; */
  width: 100%;
  height: 56px;
  /* background: #13202d; */
}

/* css for right side content */

.pdPage-main .pdPage-rs {
  background-color: #032744;
  border-radius: 12px;
  /* margin: 10px 10px 10px 0px; */
  padding: 16px;
  height: 100%;
}

.pdPage-rs .offer-box {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ffffff;
}

.pdPage-rs .pd-card {
  background-color: #064165;
  border-radius: 15px;
  color: #f1f5f9;
  margin: 8px 0px;
}

.pdPage-rs .pd-card-header {
  color: #f1f5f9;
}

.pdPage-rs .pd-card-chip {
  margin-top: 5px;
  border-radius: 20px;
  font-size: 16px;
  background-color: #396d8d;
  color: #ffffff;
}

.pdPage-rs .pd-card-chip-rj {
  margin-top: 5px;
  border-radius: 20px;
  font-size: 16px;
  background-color: #e2e8f0;
  color: #94a3b8;
}

.pdPage-rs .pd-tranche {
  margin: 16px 0px;
  color: #ffffff;
}

/* .pdPage-rs .pd-money {
  margin: 16px;
  color: #ffffff;
  font-size: 32px;
} */

/* .pdPage-rs .pd-amount {
  color: #94a3b8;
  font-size: var(--font-base);
  margin-top: 8px;
  margin: 16px;
} */

.pdPage-rs .pd-vr-val {
  display: flex;
  justify-content: space-between;
  margin: 16px;
}

.pdPage-rs .pd-vr-fs16 {
  font-size: 16px;
  color: #ffffff;
}

.pdPage-rs .pd-vr-fs13 {
  color: #94a3b8;
  font-size: 13px;
}

.pdPage-rs .pd-vr-fs13-w {
  color: #ffffff;
  font-size: 13px;
}

.pdPage-rs .pd-df-jc {
  display: flex;
  justify-content: space-between;
}

.pdPage-rs .pd-card-avatar {
  border-radius: 12px;
  background-color: #002942;
}

@media only screen and (max-width: 620px) {
  .pdPage-main .sale {
    margin: 10px;
  }

  .pdPage-main .real-state {
    margin: 10px;
  }

  .pdPage-main .multi {
    margin: 10px;
  }

  .pdPage-main .editButtonIcon {
    margin: 10px;
  }

  .pdPage-main .viewButtonIcon {
    margin: 10px;
  }

  .pdPage-main .imageTitle {
    display: flex;
    justify-content: center;
  }

  .pdPage-main .custom-options {
    flex-direction: column;
  }

  .pdPage-main .textElementTitle {
    display: flex;
    justify-content: center;
  }
}

/* Project details sl 1 */
.pdSl1-popup {
  position: relative;
  width: 50%;
  height: 450px;
  background-color: #13202d;
  border: 1px solid #000;
  box-shadow: 24;
  left: 24%;
  top: 10%;
  right: 6%;
  border-radius: 24px;
}

.pdSl1-popup .pdSl1-popup-box {
  display: flex;
  justify-content: space-between;
}

.pdSl1-popup .logo {
  width: 58px;
  height: 89px;
  margin: 4%;
}

.pdSl1-popup .closeIcon {
  margin: 4%;
}

.pdSl1-popup .subtitle {
  font-size: 28px;
  display: flex;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  justify-content: center;
  width: 555px;
  margin: 30px 20px 20px 20px;
}

.pdSl1-popup .svTY-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 60%;
  height: 52px;
  background: #83a4b8;
  border-radius: 16px;
  margin: 20px;
  color: #ffffff;
  text-transform: none;
}

.details-thumbnails-slider .image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
.details-thumbnails-slider .image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
  height: 426px;
}

.details-thumbnails-slider .image-gallery-content.image-gallery-thumbnails-left.fullscreen .image-gallery-slide .image-gallery-image,
.details-thumbnails-slider .image-gallery-content.image-gallery-thumbnails-right.fullscreen .image-gallery-slide .image-gallery-image {
  height: auto;
}

#scroller-wrap {
  max-height: 500px;
  /* Set the maximum height for the container */
  overflow-y: scroll;
  /* Enable vertical scrolling */
}

.content-section.Parcels-scrollbar {
  margin-right: 15px;
}

#scrolled-box {
  transition: transform 0.5s ease;
  /* Make the transition smooth */
}



#empty-content {
  min-height: 500px;
  /* Set the minimum height for the empty content */
}

@media only screen and (max-width: 900px) {
  .pdSl1-popup .logo {
    width: 30px;
    height: 35px;
  }

  .pdSl1-popup .checkIcon {
    width: 35px;
    height: 35px;
  }

  .pdSl1-popup .subtitle {
    font-size: 22px;
  }

  .pdSl1-popup {
    width: 80%;
    left: 10%;
    right: 10%;
    height: 420px;
  }
}

@media only screen and (max-width: 600px) {
  .pdSl1-popup .logo {
    width: 30px;
    height: 35px;
  }

  .pdSl1-popup .checkIcon {
    width: 35px;
    height: 35px;
  }

  .pdSl1-popup .subtitle {
    font-size: 22px;
    margin: 30px 10px 30px 10px;
  }

  .pdSl1-popup {
    width: 80%;
    left: 10%;
    right: 10%;
    height: 420px;
  }
}