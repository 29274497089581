
.main-footer a {
    color: var(--blueGray-500);
    -webkit-transition: color 0.25s ease;
    transition: color 0.25s ease;
}
.main-footer a:hover {
    color: var(--white-color);
}
.ftr-menu-section .footer-colum .title { 
    color: var(--white-color);
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.1px;
    margin-bottom: 28px;
}
.ftr-menu-section .ftr-menus .menu-item {
    margin-bottom: 28px;
    text-align: center;
}
.ftr-menu-section .ftr-menus .menu-item a {
    font-size: var(--font-14);
    text-align: center;
    letter-spacing: 0.25px;
}
.ftr-termsPrivacy-menus .menu-item a {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

@media only screen and (max-width: 560px) {
    .ftr-termsPrivacy-menus .menu-item {
        width: 50%;
        margin: 3px 0;
        text-align: center;
    }
}